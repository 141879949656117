import React from "react"
import KickrollLogo from "../img/kickroll-logo.png"

export const HeroSection = ({ setIsOpen }) => {
    return (
        <div className="hero -mt-16">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 h-screen flex items-center relative">
                <div className="wrapper">
                    <img
                        className="md:max-w-xl"
                        src={KickrollLogo}
                        alt="hero logo"
                    />
                    <p className="">vi gör balla hemsidor.</p>
                </div>
                <div className="absolute bottom-14 sm:bottom-16 sm:right-12 text-center">
                    <div className="flying-contact max-w-xs p-8 rounded-3xl flex flex-col justify-evenly">
                        <p className="font-medium">Woho! du har hittat hit 🥳</p>
                        <p className="font-light mb-4 sm:mb-12">
                            Varför vänta ännu längre på en ny, fräsch hemsida?
                            Kontakta oss direkt.
                        </p>

                        <button onClick={() => setIsOpen(true)}
                            className={
                                "bg-black text-white cursor-pointer rounded-full py-3 px-8 cursor-auto"
                            }
                        >
                            kontaktförfrågan
                        </button>
                    </div>
                </div>
            </div>
            <a
                aria-hidden
                href="#main"
                className="scroll-down-link scroll-down-arrow"
                data-iconfont="ETmodules"
                data-icon
            >
                {" "}
            </a>
        </div>
    )
}
