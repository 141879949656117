import React from 'react'
import Intro from './Intro'
import Projects from "./Projects"
import Team from "./Team"

const Main = () => {
  return (
    <div id="main" className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <Intro/>
        <Projects />
        <Team />
    </div>
  )
}

export default Main
