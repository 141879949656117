// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"

import { getFirestore, collection, query, getDocs } from "firebase/firestore"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY_FIREBASE,
    authDomain: "kickroll-3ca91.firebaseapp.com",
    projectId: "kickroll-3ca91",
    storageBucket: "kickroll-3ca91.appspot.com",
    messagingSenderId: "70733209368",
    appId: "1:70733209368:web:2162eed2d36bf64f4c486d",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

export const getProjects = async () => {
    const col = collection(db, "projects")
    const q = query(col)
    const snap = await getDocs(q)
    const docs = snap.docs.map((doc) => ({
        id: doc.id,
        reference: doc.ref,
        ...doc.data(),
    }))
    return docs
}
