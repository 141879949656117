import React from "react"
import Button from "./Button"

const Team = () => {
  return (
    <div id="our-concept" className="sm:grid sm:grid-cols-2 sm:gap-48 my-16">
      <div>
        <h2 className="text-2xl font-bold">vårt koncept.</h2>
        <p className="mt-4">
          Att göra häftiga applikationer och implementationer som fungerar för just din verksamhet.
          Om det så är en enklare hemsida, webbutik, unik ux/ui design eller något helt annat så finns vi med och hjälper dig hela vägen 🥳
        </p>
      </div>
      <div className="flex items-start flex-wrap gap-4 my-4">
        <Button className="bg-gray-200">bokningssystem</Button>
        <Button className="bg-gray-200">webbutveckling</Button>
        <Button className="bg-gray-200">ux/ui</Button>
        <Button className="bg-gray-200">grafisk profil</Button>
        <Button className="bg-gray-200">förvaltning</Button>
        <Button className="bg-gray-200">seo</Button>
        <Button className="bg-gray-200">koncept & strategi</Button>
        <Button className="bg-gray-200">wordpress</Button>
        <Button className="bg-gray-200">woocommerce</Button>
        <Button className="bg-gray-200">react</Button>
      </div>
    </div>
  )
}

export default Team
