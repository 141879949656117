import { Dialog } from "@headlessui/react"
import { useForm } from "react-hook-form"
import Button from "./Button"
import { useState } from "react"
import toast, { Toaster } from "react-hot-toast"

export default function MyDialog({ isOpen, setIsOpen }) {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm()

    const [isSending, setIsSending] = useState(false)

    const notify = (message, { icon }) => {
        toast(message, { position: "bottom-center", icon })
    }

    const onSubmit = async (data) => {
        try {
            setIsSending(true)
            const res = await fetch(
                "https://us-central1-kickroll-3ca91.cloudfunctions.net/emailMessage",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    body: JSON.stringify(data),
                }
            )

            if (res.ok) {
                notify("Ditt mail har skickats! Vi återkopplar!", {
                    icon: "🥳",
                })
                setTimeout(() => {
                    //
                    setIsSending(false)
                    reset()
                    setIsOpen(false)
                }, 3000)
            }
        } catch (e) {
            notify("Ditt mail kunde tyvärr ej skickas", { icon: "😞" })
            setIsSending(false)
            console.log(e)
        }
    }

    return (
        <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            className="relative z-50"
        >
            {/* The backdrop, rendered as a fixed sibling to the panel container */}
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

            {/* Full-screen container to center the panel */}
            <div className="fixed inset-0 flex items-center justify-center p-4">
                {/* The actual dialog panel  */}
                <Dialog.Panel className="rounded bg-white w-full max-w-xl overflow-hidden">
                    <Dialog.Title className="text-center px-4 py-5 sm:p-6">
                        <h2 className="text-2xl font-bold">
                            Säg hej till oss 😊
                        </h2>
                    </Dialog.Title>
                    <div className="overflow-hidden shadow sm:rounded-md">
                        <div className="bg-white px-4 py-5 sm:p-6">
                            <div className="grid grid-cols-6 gap-6"></div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="first-name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Namn
                                    </label>
                                    <input
                                        {...register("name", {
                                            required: true,
                                            minLength: 2,
                                            maxLength: 20,
                                        })}
                                        type="text"
                                        name="name"
                                        id="name"
                                        autoComplete="given-name"
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                    <p className="font-urw text-sm mt-1 text-gray-500">
                                        {errors.name?.type === "required" &&
                                            "Vi vill gärna veta ditt namn 😊"}
                                    </p>
                                </div>
                                <div className="mt-4 col-span-6 sm:col-span-4">
                                    <label
                                        htmlFor="email-address"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Email
                                    </label>
                                    <input
                                        {...register("email", {
                                            required: true,
                                            maxLength: 40,
                                        })}
                                        type="text"
                                        name="email"
                                        id="email"
                                        autoComplete="email"
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                    <p className="font-urw text-sm mt-1 text-gray-500">
                                        {errors.email?.type === "required" &&
                                            "Din email behöver vi med 📧"}
                                    </p>
                                </div>
                                <div className="mt-4 col-span-6 sm:col-span-4">
                                    <label
                                        htmlFor="phone"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Mobiltelefon
                                    </label>
                                    <input
                                        {...register("phone", {
                                            required: true,
                                        })}
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        autoComplete="phone"
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                    <p className="font-urw text-sm mt-1 text-gray-500">
                                        {errors.phone?.type === "required" &&
                                            "Ditt mobilnummer om vi vill ringa dig 📞"}
                                    </p>
                                </div>

                                <div className="mt-4 col-span-6 sm:col-span-4">
                                    <label
                                        htmlFor="about"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        About
                                    </label>
                                    <div className="mt-1">
                                        <textarea
                                            {...register("message", {
                                                required: true,
                                                minLength: 10,
                                            })}
                                            id="message"
                                            name="message"
                                            rows="3"
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        ></textarea>
                                        <p className="font-urw text-sm mt-1 text-gray-500">
                                            {errors.message?.type ===
                                                "required" &&
                                                "Åja lite mer kan du väl berätta? 😎"}
                                        </p>
                                    </div>
                                    <p className="mt-2 text-sm text-gray-500">
                                        Skriv ett trevligt hallå till oss 👋
                                    </p>
                                </div>
                                <Button
                                    disabled={isSending}
                                    className="bg-kickroll-pink mt-4 w-full font-bold text-2xl cursor-pointer"
                                    type="submit"
                                >
                                    {isSending ? "SKICKAR..." : "SKICKA"}
                                </Button>
                            </form>
                        </div>
                    </div>
                </Dialog.Panel>
            </div>
            <Toaster />
        </Dialog>
    )
}
