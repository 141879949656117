import React from 'react'

const Button = ( {className='', children}) => {
  return (
    <button className={className + ` rounded-full py-3 px-8 cursor-auto`}>
        {children ? children : "no text set"}
    </button>
  )
}

export default Button
