import React from "react"
import ContentLoad from "./Loader"
import Project from "./Project"
import { useQuery } from "@tanstack/react-query"
import { getProjects } from "../lib/firebase"

const Projects = () => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    ["projects"],
    getProjects
  )

  if (isLoading)
    return (
      <div className="sm:grid sm:grid-cols-2 sm:gap-16 my-8">
        <ContentLoad />
        <ContentLoad />
      </div>
    )

  if (isError) return <p>Projekten kunde ej hämtas 😞</p>

  //   // TODO Just used for test data instead of calling Firestore
  //   const isSuccess = true
  //   const data = [
  //     {
  //       title: "Cirkus",
  //       description: "Bla bla bla",
  //       url: "www.google.se",
  //       imgurl:
  //         "https://lh3.googleusercontent.com/drive-viewer/AJc5JmRJ1H5396uNLrCLfhIlXx0RISK-1APaw3lM5dGzRNPsyUU5BUPsBac7qFR71yTigOVYGMlwogo=w1557-h1232",
  //       tags: ["react", "wordpress", "material ui"],
  //     },
  //   ]

  return (
    isSuccess && (
      <div className="sm:grid sm:grid-cols-2 sm:gap-16 my-8">
        {data.map(({ title, description, url, imgurl, tags }) => (
          <Project
            key={title}
            title={title}
            description={description}
            url={url}
            imgurl={imgurl}
            tags={tags}
          />
        ))}
      </div>
    )
  )
}

export default Projects
