import "./App.css"
import Header from "./components/Header"
import Main from "./components/Main"
import Footer from "./components/Footer"
import { HeroSection } from "./components/HeroSection"
import AppProviders from "./AppProviders"
import MyDialog  from "./components/MyDialog"

import { useState } from "react"

function App() {

    const [isOpen, setIsOpen] = useState(false)

    return (
        <AppProviders>
            <div className="App font-urw">
                <Header />
                <HeroSection setIsOpen={setIsOpen} />
                <Main />
                <Footer />
                <MyDialog isOpen={isOpen} setIsOpen={setIsOpen} />
            </div>
        </AppProviders>
    )
}

export default App
