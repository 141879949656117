import React from "react"
import Button from "./Button"
import CoverImage from "../img/project-cover.png"

const Project = ({ title, description, url, imgurl, tags }) => {
    return (
        <div className="rounded-2xl bg-zinc-50 px-4 sm:px-10 mt-10 sm:mt-0 py-8 sm:py-16 sm:py-8 drop-shadow-xl sm:flex sm:flex-col">
            <figure className="w-full">
                <a {...(url ? { href: url } : {})}>
                    <img
                        className="sm:order-1 object-cover h-48 w-full"
                        src={imgurl ?? CoverImage}
                        alt="lol"
                    />
                </a>
            </figure>
            <h2 className="text-2xl font-bold mt-4 sm:mt-6 sm:order-2">
                {title ?? "Titel saknas!"}
            </h2>
            <div className="flex flex-wrap items-start sm:order-3">
                {tags ? (
                    tags.map((tag, i) => (
                        <Button
                            key={i}
                            className="bg-kickroll-pink text-xs px-3 py-1 mt-2 mr-2"
                        >
                            {tag}
                        </Button>
                    ))
                ) : (
                    <Button className="bg-kickroll-pink text-xs px-3 py-1 mt-2 mr-2">
                        Taggar saknas
                    </Button>
                )}
            </div>
            <p className="mt-4 sm:order-4">
                {description ?? "Beskrivning saknas"}
            </p>
        </div>
    )
}

export default Project
