import { EnvelopeIcon, MapPinIcon } from "@heroicons/react/24/outline"
import { FaDribbbleSquare, FaGithub, FaLinkedin } from "react-icons/fa"
import React from "react"

import KickrollLogo from "../img/kickroll-logo.png"

const Footer = () => {
  return (
    <div id="contact" className="footer">
      <div className="wrapper mx-auto max-w-7xl px-8 sm:px-6 lg:px-8">
        <div className="py-10">
          <img
            className="max-w-xs sm:max-w-lg"
            src={KickrollLogo}
            alt="logo footer"
          />
          <p className="text-white sm:text-4xl">vi skapar balla hemsidor.</p>
        </div>
        <div className="line bg-kickroll-pink h-1 sm:max-w-xs"></div>
        <div className="address h-44 text-white flex flex-col justify-evenly">
          <div className="flex items-center">
            <MapPinIcon className="h-8" />
            <p className="ml-4">Internet 🌍</p>
          </div>
          <div className="flex items-center">
            <EnvelopeIcon className="h-8" />
            <p className="ml-4">jacob@kickroll.se</p>
          </div>
        </div>
        <div className="line bg-kickroll-pink h-1 sm:max-w-xs"></div>
        <div className="team-links h-48 flex flex-col justify-between">
          <div className="mt-8">
            <p>Jacobs snabblänkar</p>
            <div className="flex gap-4 text-white mt-2">
              <a href="https://github.com/jccbbb/">
                <FaGithub size={24} title={"Github"} />
              </a>
              <a href="https://www.linkedin.com/in/jacobgullberg/">
                <FaLinkedin size={24} title={"Linkedin"} />
              </a>
            </div>
          </div>
          <div className="mt-8 hidden">
            <p>Matildas snabblänkar</p>
            <div className="flex gap-4 text-white mt-2">
              <FaDribbbleSquare size={24} title={"Dribbble"} />
              <a href="https://www.linkedin.com/in/matilda-sander/">
                <FaLinkedin size={24} title={"Linkedin"} />
              </a>
            </div>
          </div>
        </div>
        <div className="copyright text-center py-12 text-white font-bold">
          <p>copyright kickroll 2022</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
