import React from "react"

const Intro = () => {
  return (
    <div
      id="ongoing-projects"
      className="grid sm:grid-cols-2 gap-4 sm:gap-8 sm:my-16 mt-8"
    >
      <div className="flex self-center justify-start">
        <h1 className="previous-customers text-4xl font-bold">
          pågående projekt.
        </h1>
      </div>
      <div className="max-w-md">
        <p></p>
      </div>
    </div>
  )
}

export default Intro
